<div class="container-fluid mt-3">

  <nav mat-tab-nav-bar class="user-dashboard-tabs">
    <a mat-tab-link routerLink="./employees" routerLinkActive="active-tabs">
      <div class="lbl">
        <label class="pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <path
              d="M4.99998 4.79504C4.35833 4.79504 3.80903 4.56657 3.35208 4.10964C2.89514 3.65269 2.66667 3.10339 2.66667 2.46174C2.66667 1.82007 2.89514 1.27077 3.35208 0.813835C3.80903 0.35689 4.35833 0.128418 4.99998 0.128418C5.64164 0.128418 6.19094 0.35689 6.64788 0.813835C7.10483 1.27077 7.3333 1.82007 7.3333 2.46174C7.3333 3.10339 7.10483 3.65269 6.64788 4.10964C6.19094 4.56657 5.64164 4.79504 4.99998 4.79504ZM0 9.87195V8.38992C0 8.06343 0.0886776 7.76109 0.266033 7.48289C0.443378 7.20469 0.680339 6.9908 0.976917 6.84122C1.63589 6.51814 2.30064 6.27584 2.97115 6.1143C3.64166 5.95277 4.31794 5.872 4.99998 5.872C5.68203 5.872 6.35831 5.95277 7.02882 6.1143C7.69933 6.27584 8.36407 6.51814 9.02305 6.84122C9.31963 6.9908 9.55659 7.20469 9.73393 7.48289C9.91129 7.76109 9.99997 8.06343 9.99997 8.38992V9.87195H0ZM0.999983 8.87197H8.99998V8.38992C8.99998 8.25487 8.96088 8.12987 8.88267 8.01492C8.80447 7.89996 8.69827 7.80616 8.56408 7.7335C7.98973 7.4506 7.40407 7.23629 6.80712 7.09055C6.21016 6.94483 5.60778 6.87197 4.99998 6.87197C4.39218 6.87197 3.78981 6.94483 3.19285 7.09055C2.59589 7.23629 2.01024 7.4506 1.43588 7.7335C1.30169 7.80616 1.1955 7.89996 1.1173 8.01492C1.03909 8.12987 0.999983 8.25487 0.999983 8.38992V8.87197ZM4.99998 3.79507C5.36665 3.79507 5.68054 3.66451 5.94165 3.4034C6.20276 3.14229 6.33332 2.8284 6.33332 2.46174C6.33332 2.09507 6.20276 1.78118 5.94165 1.52007C5.68054 1.25896 5.36665 1.1284 4.99998 1.1284C4.63332 1.1284 4.31943 1.25896 4.05832 1.52007C3.79721 1.78118 3.66665 2.09507 3.66665 2.46174C3.66665 2.8284 3.79721 3.14229 4.05832 3.4034C4.31943 3.66451 4.63332 3.79507 4.99998 3.79507Z" />
          </svg>
          <span class="ml-2">Employees</span>
        </label>
      </div>
    </a>
    <a mat-tab-link routerLink="./absent" routerLinkActive="active-tabs">
      <div class="lbl">
        <label class="pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <path
              d="M4.99998 4.79504C4.35833 4.79504 3.80903 4.56657 3.35208 4.10964C2.89514 3.65269 2.66667 3.10339 2.66667 2.46174C2.66667 1.82007 2.89514 1.27077 3.35208 0.813835C3.80903 0.35689 4.35833 0.128418 4.99998 0.128418C5.64164 0.128418 6.19094 0.35689 6.64788 0.813835C7.10483 1.27077 7.3333 1.82007 7.3333 2.46174C7.3333 3.10339 7.10483 3.65269 6.64788 4.10964C6.19094 4.56657 5.64164 4.79504 4.99998 4.79504ZM0 9.87195V8.38992C0 8.06343 0.0886776 7.76109 0.266033 7.48289C0.443378 7.20469 0.680339 6.9908 0.976917 6.84122C1.63589 6.51814 2.30064 6.27584 2.97115 6.1143C3.64166 5.95277 4.31794 5.872 4.99998 5.872C5.68203 5.872 6.35831 5.95277 7.02882 6.1143C7.69933 6.27584 8.36407 6.51814 9.02305 6.84122C9.31963 6.9908 9.55659 7.20469 9.73393 7.48289C9.91129 7.76109 9.99997 8.06343 9.99997 8.38992V9.87195H0ZM0.999983 8.87197H8.99998V8.38992C8.99998 8.25487 8.96088 8.12987 8.88267 8.01492C8.80447 7.89996 8.69827 7.80616 8.56408 7.7335C7.98973 7.4506 7.40407 7.23629 6.80712 7.09055C6.21016 6.94483 5.60778 6.87197 4.99998 6.87197C4.39218 6.87197 3.78981 6.94483 3.19285 7.09055C2.59589 7.23629 2.01024 7.4506 1.43588 7.7335C1.30169 7.80616 1.1955 7.89996 1.1173 8.01492C1.03909 8.12987 0.999983 8.25487 0.999983 8.38992V8.87197ZM4.99998 3.79507C5.36665 3.79507 5.68054 3.66451 5.94165 3.4034C6.20276 3.14229 6.33332 2.8284 6.33332 2.46174C6.33332 2.09507 6.20276 1.78118 5.94165 1.52007C5.68054 1.25896 5.36665 1.1284 4.99998 1.1284C4.63332 1.1284 4.31943 1.25896 4.05832 1.52007C3.79721 1.78118 3.66665 2.09507 3.66665 2.46174C3.66665 2.8284 3.79721 3.14229 4.05832 3.4034C4.31943 3.66451 4.63332 3.79507 4.99998 3.79507Z" />
          </svg>
          <span class="ml-2">Absent Employees</span>
        </label>
      </div>
    </a>
    <a mat-tab-link routerLink="./leave" routerLinkActive="active-tabs">
      <div class="lbl">
        <label class="pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <path
              d="M4.99998 4.79504C4.35833 4.79504 3.80903 4.56657 3.35208 4.10964C2.89514 3.65269 2.66667 3.10339 2.66667 2.46174C2.66667 1.82007 2.89514 1.27077 3.35208 0.813835C3.80903 0.35689 4.35833 0.128418 4.99998 0.128418C5.64164 0.128418 6.19094 0.35689 6.64788 0.813835C7.10483 1.27077 7.3333 1.82007 7.3333 2.46174C7.3333 3.10339 7.10483 3.65269 6.64788 4.10964C6.19094 4.56657 5.64164 4.79504 4.99998 4.79504ZM0 9.87195V8.38992C0 8.06343 0.0886776 7.76109 0.266033 7.48289C0.443378 7.20469 0.680339 6.9908 0.976917 6.84122C1.63589 6.51814 2.30064 6.27584 2.97115 6.1143C3.64166 5.95277 4.31794 5.872 4.99998 5.872C5.68203 5.872 6.35831 5.95277 7.02882 6.1143C7.69933 6.27584 8.36407 6.51814 9.02305 6.84122C9.31963 6.9908 9.55659 7.20469 9.73393 7.48289C9.91129 7.76109 9.99997 8.06343 9.99997 8.38992V9.87195H0ZM0.999983 8.87197H8.99998V8.38992C8.99998 8.25487 8.96088 8.12987 8.88267 8.01492C8.80447 7.89996 8.69827 7.80616 8.56408 7.7335C7.98973 7.4506 7.40407 7.23629 6.80712 7.09055C6.21016 6.94483 5.60778 6.87197 4.99998 6.87197C4.39218 6.87197 3.78981 6.94483 3.19285 7.09055C2.59589 7.23629 2.01024 7.4506 1.43588 7.7335C1.30169 7.80616 1.1955 7.89996 1.1173 8.01492C1.03909 8.12987 0.999983 8.25487 0.999983 8.38992V8.87197ZM4.99998 3.79507C5.36665 3.79507 5.68054 3.66451 5.94165 3.4034C6.20276 3.14229 6.33332 2.8284 6.33332 2.46174C6.33332 2.09507 6.20276 1.78118 5.94165 1.52007C5.68054 1.25896 5.36665 1.1284 4.99998 1.1284C4.63332 1.1284 4.31943 1.25896 4.05832 1.52007C3.79721 1.78118 3.66665 2.09507 3.66665 2.46174C3.66665 2.8284 3.79721 3.14229 4.05832 3.4034C4.31943 3.66451 4.63332 3.79507 4.99998 3.79507Z" />
          </svg>
          <span class="ml-2">On Leave Employees</span>
        </label>
      </div>
    </a>
    <a mat-tab-link routerLink="./new-employees" routerLinkActive="active-tabs">
      <div class="lbl">
        <label class="pointer ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10">
            <path
              d="M11.0833 6.16684V4.16684H9.08333V3.16687H11.0833V1.16687H12.0833V3.16687H14.0833V4.16684H12.0833V6.16684H11.0833ZM5 4.79504C4.35834 4.79504 3.80904 4.56657 3.3521 4.10964C2.89515 3.65269 2.66668 3.10339 2.66668 2.46174C2.66668 1.82007 2.89515 1.27077 3.3521 0.813835C3.80904 0.35689 4.35834 0.128418 5 0.128418C5.64165 0.128418 6.19095 0.35689 6.6479 0.813835C7.10484 1.27077 7.33332 1.82007 7.33332 2.46174C7.33332 3.10339 7.10484 3.65269 6.6479 4.10964C6.19095 4.56657 5.64165 4.79504 5 4.79504ZM3.19163e-05 9.87195V8.38992C3.19163e-05 8.06343 0.0886986 7.76109 0.266032 7.48289C0.443387 7.20469 0.680354 6.9908 0.976932 6.84122C1.63591 6.51814 2.30065 6.27584 2.97117 6.1143C3.64168 5.95277 4.31795 5.872 5 5.872C5.68204 5.872 6.35832 5.95277 7.02883 6.1143C7.69934 6.27584 8.36409 6.51814 9.02307 6.84122C9.31964 6.9908 9.5566 7.20469 9.73395 7.48289C9.9113 7.76109 9.99998 8.06343 9.99998 8.38992V9.87195H3.19163e-05ZM0.999999 8.87197H9V8.38992C9 8.25487 8.96089 8.12987 8.88268 8.01492C8.80448 7.89996 8.69829 7.80616 8.5641 7.7335C7.98974 7.4506 7.40409 7.23629 6.80713 7.09055C6.21018 6.94483 5.6078 6.87197 5 6.87197C4.3922 6.87197 3.78982 6.94483 3.19287 7.09055C2.59591 7.23629 2.01025 7.4506 1.4359 7.7335C1.30171 7.80616 1.19552 7.89996 1.11732 8.01492C1.0391 8.12987 0.999999 8.25487 0.999999 8.38992V8.87197ZM5 3.79507C5.36667 3.79507 5.68055 3.66451 5.94167 3.4034C6.20278 3.14229 6.33333 2.8284 6.33333 2.46174C6.33333 2.09507 6.20278 1.78118 5.94167 1.52007C5.68055 1.25896 5.36667 1.1284 5 1.1284C4.63333 1.1284 4.31944 1.25896 4.05833 1.52007C3.79722 1.78118 3.66667 2.09507 3.66667 2.46174C3.66667 2.8284 3.79722 3.14229 4.05833 3.4034C4.31944 3.66451 4.63333 3.79507 5 3.79507Z" />
          </svg><span class="ml-2">New Employees</span></label>
      </div>
    </a>
   
    <a mat-tab-link routerLink="./alumini" routerLinkActive="active-tabs">
      <div class="lbl">
        <label class="pointer ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10">
            <path
              d="M9.70513 4.16684V3.16687H13.3718V4.16684H9.70513ZM5 4.79504C4.35834 4.79504 3.80904 4.56657 3.3521 4.10964C2.89515 3.65269 2.66668 3.10339 2.66668 2.46174C2.66668 1.82007 2.89515 1.27077 3.3521 0.813835C3.80904 0.35689 4.35834 0.128418 5 0.128418C5.64165 0.128418 6.19095 0.35689 6.6479 0.813835C7.10484 1.27077 7.33331 1.82007 7.33331 2.46174C7.33331 3.10339 7.10484 3.65269 6.6479 4.10964C6.19095 4.56657 5.64165 4.79504 5 4.79504ZM3.19163e-05 9.87195V8.38992C3.19163e-05 8.06343 0.0886986 7.76109 0.266032 7.48289C0.443387 7.20469 0.680354 6.9908 0.976932 6.84122C1.63591 6.51814 2.30065 6.27584 2.97117 6.1143C3.64168 5.95277 4.31795 5.872 5 5.872C5.68204 5.872 6.35832 5.95277 7.02883 6.1143C7.69934 6.27584 8.36409 6.51814 9.02306 6.84122C9.31964 6.9908 9.5566 7.20469 9.73395 7.48289C9.9113 7.76109 9.99998 8.06343 9.99998 8.38992V9.87195H3.19163e-05ZM0.999999 8.87197H9V8.38992C9 8.25487 8.96089 8.12987 8.88268 8.01492C8.80448 7.89996 8.69829 7.80616 8.5641 7.7335C7.98974 7.4506 7.40409 7.23629 6.80713 7.09055C6.21018 6.94483 5.6078 6.87197 5 6.87197C4.3922 6.87197 3.78982 6.94483 3.19287 7.09055C2.59591 7.23629 2.01025 7.4506 1.4359 7.7335C1.30171 7.80616 1.19552 7.89996 1.11732 8.01492C1.0391 8.12987 0.999999 8.25487 0.999999 8.38992V8.87197ZM5 3.79507C5.36666 3.79507 5.68055 3.66451 5.94166 3.4034C6.20278 3.14229 6.33333 2.8284 6.33333 2.46174C6.33333 2.09507 6.20278 1.78118 5.94166 1.52007C5.68055 1.25896 5.36666 1.1284 5 1.1284C4.63333 1.1284 4.31944 1.25896 4.05833 1.52007C3.79722 1.78118 3.66666 2.09507 3.66666 2.46174C3.66666 2.8284 3.79722 3.14229 4.05833 3.4034C4.31944 3.66451 4.63333 3.79507 5 3.79507Z" />
          </svg>
          <span class="ml-2">Alumni employees</span></label>
      </div>
    </a>

  </nav>
  <router-outlet></router-outlet>
</div>