import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/models/task';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ExitChecklistComponent } from 'src/app/components/exit-checklist/exit-checklist.component';
import {
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { JobTitles } from 'src/app/services/user/user-enum';
import { map } from 'rxjs/operators';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { DeclineFeedbackComponent } from '../decline-feedback/decline-feedback.component';
import { AttendanceRegularizationService } from 'src/app/services/attendance-regularization.service';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { RejectLeaveComponent } from '../reject-leave/reject-leave.component';
import * as taskActions from '../../../store/actions/task.action';
import { AttendanceDTO } from 'src/app/dtos/AttendanceDTO';
import { RejectregularizeComponent } from '../rejectregularize/rejectregularize.component';
import { ApplyNewleaveComponent } from '../../leave-management/leave-attendance/apply-newleave/apply-newleave.component';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import {
  ApprovalStatus,
  TypeOfLeave,
} from 'src/app/services/leaveManagment/leave-enum';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';
import { KnowledgeTransferComponent } from '../knowledgeTransfer/knowledge-transfer/knowledge-transfer.component';
import { ExitChecklistDTO } from 'src/app/dtos/ExitChecklistDTO';
import { ExitChecklist } from 'src/app/services/exit-checklist/exit-checklist.service';
import { TypeOfTask } from 'src/app/services/user/user-enum';
import { ResignationAcceptComponent } from '../resignation/resignation-accept/resignation-accept/resignation-accept.component';

import { ResignationRejectComponent } from '../resignation/resignation-reject/resignation-reject/resignation-reject.component';
import { WfoService } from 'src/app/services/work-from-office/wfo.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';
import { RegularizeComponent } from '../../leave-management/attendance-calendar/attendance-details/regularize/regularize.component';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
  @Output() closeDialogEvent = new EventEmitter<void>();
  obj: any;
  @Input() taskData: Task;
  @Input() taskStatus: String;
  assignForUser;
  iscompletedtask = false;
  assignToUSer;
  taskColor: String;
  actions: any;
  exitDTO = new ExitChecklistDTO();
  attendanceDTO: AttendanceDTO;
  TASK_ACTION_CONFIGURATION = {
    CREATE_KT: {
      actions: [
        {
          text: 'Initiate',
          type: 'redirect',
          action: (trnxId: string) => {
            this.createKt(trnxId);
          },
        },
      ],
    },

    RESIGNATION_APPROVAL: {
      actions: [
        {
          text: 'Accept',
          text1: 'Reject',
          type: 'redirect',
          action: (trnxId: string) => {
            this.approveResignation(trnxId);
          },
          action1: (trnxId: string) => {
            this.rejectResignation(trnxId);
          },
        },
      ],
    },

    CREATE_CHECKLIST: {
      actions: [
        {
          text: 'Exit Checklist',
          type: 'redirect',
          action: (trnxId: string) => {
            this.exitChecklist(trnxId);
          },
        },
      ],
    },
    UPDATE_CHECKLIST: {
      actions: [
        {
          text: 'Update Checklist',
          type: 'redirect',
          action: (trnxId: string) => {
            this.exitChecklist(trnxId);
          },
        },
      ],
    },
    SUBMIT_FEEDBACK: {
      actions: [
        {
          text: 'Submit',
          text1: 'Decline',
          type: 'redirect',
          action: (trnxId: string) => {
            this.OpenJSonForm();
          },
          action1: (trnxId: string) => {
            this.decline();
          },
        },
      ],
    },
    SUBMIT_REAL_TIME_FEEDBACK: {
      actions: [
        {
          text: 'Submit',
          text1: 'Cancel',
          type: 'redirect',
          action: (trnxId: string) => {
            this.OpenJSonForm();
          },
          action1: (trnxId: string) => {
            this.decline();
          },
        },
      ],
    },
    DECLINE_FEEDBACK: {
      actions: [
        {
          text: 'Decline',
          type: 'redirect',
        },
      ],
    },
    REGULARIZATION: {
      actions: [
        {
          text: 'Approve',
          text1: 'Reject',
          type: 'redirect',
          action: (trnx: string) => {
            this.acceptordeclineRegularalization(trnx, true);
          },
          action1: (trnx: String) => {
            this.acceptordeclineRegularalization(trnx, false);
          },
        },
      ],
    },
    REGULARIZATION_REJECT: {
      actions: [
        {
          text: 'Apply Leave',
          text1: 'Regularization',
          type: 'redirect',
          action: (trnx: string) => {
            this.applyLeaveAfterRegularizationReject(trnx);
          },
          action1: (trnx: string) => {
            this.applyagainAfterRegularizationReject(trnx);
          },
        },
      ],
    },
    LEAVE_APPROVAL: {
      actions: [
        {
          text: 'Approve',
          text1: 'Reject',
          type: 'redirect',
          action: (trnx: string) => {
            this.approveOrRejectByCM(trnx, 'APPROVED');
          },
          action1: (trnx: String) => {
            this.approveOrRejectByCM(trnx, 'REJECTED');
          },
        },
      ],
    },
    WORK_FROM_OFFICE_APPROVAL: {
      actions: [
        {
          text: 'Approve',
          type: 'redirect',
          action: (trnx: string) => {
            this.approveWfoByCM(trnx);
          },
        },
      ],
    },
    WORK_FROM_OFFICE_WITHDRAW: {
      actions: [
        {
          text: 'Withdraw',
          type: 'redirect',
          action: (trnx: string) => {
            this.withdrawWfoByUser(trnx);
          },
        },
      ],
    },
    REVOKE_REQUEST: {
      actions: [
        {
          text: 'Approve',
          text1: 'Reject',
          type: 'redirect',
          action: (trnx: string) => {
            this.approveOrRejectRevokeRequest(trnx, 'APPROVED');
          },
          action1: (trnx: string) => {
            this.approveOrRejectRevokeRequest(trnx, 'REJECTED');
          },
        },
      ],
    },
    PROBATION_FEEDBACK: {
      actions: [
        {
          text: 'Submit',
          text1: 'Decline',
          type: 'redirect',
          action: (trnxId: string) => {
            this.OpenProbationJSonForm();
          },
          action1: (trnxId: string) => {
            this.declineProbation();
          },
        },
      ],
    },
    REVERSE_PROBATION_FEEDBACK: {
      actions: [
        {
          text: 'Submit',
          text1: 'Decline',
          type: 'redirect',
          action: (trnxId: string) => {
            this.OpenReverseProbationJSonForm();
          },
          action1: (trnxId: string) => {
            this.declineReverseProbation();
          },
        },
      ],
    },
  };
  constructor(
    private router: Router,
    private user: UserService,
    private dialog: MatDialog,
    private formSchema: FormsSchemaService,
    private userDetailService: UserDetailsService,
    private feedbackService: FeedbackService,
    private attendanceRegularizationService: AttendanceRegularizationService,
    private leaveApprovalService: LeaveApprovalService,
    public exitService: ExitChecklist,
    private attendanceService: AttendanceService,
    private wfoService: WfoService,
    private refreshService: RefreshService,
    private tabNavigationService: TabNavigationService,
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.feedbackService.listen().subscribe((m: any) => {
      this.ngOnInit();
    });
    if (this.taskStatus === 'due') this.taskColor = 'overdue_task';
    else if (this.taskStatus === 'completed') {
      this.taskColor = 'completed_task';
      this.iscompletedtask = true;
    } else if (this.taskStatus === 'pending') {
      this.taskColor = 'pending_task';
    } else if (this.taskStatus === 'inprogress')
      this.taskColor = 'inprogress_task';
    this.selectAction(this.taskData.typeOfTask);
  }

  transformDescription(description: string): string {
    // Use a regular expression to replace only the exact match "PLANNED_LEAVE"
    return description.replace(/\bPLANNED_LEAVE\b/g, 'Planned Leave')
      .replace(/\bUNPLANNED_LEAVE\b/g, 'Unplanned Leave')
      .replace(/\bCOMP_OFF\b/g, 'Comp Off')
      .replace(/\bCONTINGENCY_LEAVE\b/g, 'Contingency Leave')
      .replace(/\bSPECIAL_LEAVE\b/g, 'Special Leave')
      .replace(/\bPATERNITY_LEAVE\b/g, 'Paternity Leave')
      .replace(/\bLOSS_OF_PAY\b/g, 'Loss Of Pay')
      .replace(/\bMATERNITY_LEAVE\b/g, 'Maternity Leave');
  }

  createKt(trnxId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(
      KnowledgeTransferComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.loadAllTasks();
      }
    );
  }

  approveResignation(trnx) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(
      ResignationAcceptComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.loadAllTasks();
      },
      () => { }
    );
  }
  rejectResignation(trnx) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = '40%';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(
      ResignationRejectComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.loadAllTasks();
      },
      () => { }
    );
  }

  applyLeaveAfterRegularizationReject(trnx) {
    this.router.navigate(['leaveManagement', 'leave-apply']);
    this.closeDialogEvent.emit();
  }

  applyagainAfterRegularizationReject(trnx) {
    this.router.navigate(['leaveManagement', 'attendance-calendar']);
    this.closeDialogEvent.emit();
  }

  changeAttendanceStatus(trnx) {
    this.attendanceRegularizationService
      .updateAttendanceForRegularization(this.taskData.id)
      .subscribe((res) => {
        if (res.body.message === 'Success') {
          this.loadAllTasks();
        }
      });
  }

  approveOrRejectRevokeRequest(leaveApplId: string, status: string) {
    if (status == 'APPROVED') {
      const leaveApprove = {
        leaveApplicationId: leaveApplId,
        approvalStatus: 'REVOKE_REQUEST_APPROVED',
        reasonForRejection: '',
      };
      this.leaveApprovalService.update(leaveApprove).subscribe(
        (res) => {
          this.loadAllTasks();
        },
        (e) => { }
      );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '60%';
      dialogConfig.height = 'auto';
      dialogConfig.data = this.taskData;
      const dialogRef = this.dialog.open(RejectLeaveComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.loadAllTasks();
        },
        () => { }
      );
    }
    this.loadAllTasks();
  }

  acceptordeclineRegularalization(trnx, status) {
    if (status == true) {
      const attendanceRegAccept = {
        attendanceRegularizationId: trnx,
        isAccepted: status,
        reasonForAttendanceRegularizationRejection: '',
      };
      this.attendanceRegularizationService
        .getAcceptance(attendanceRegAccept)
        .subscribe(
          (res) => {
            this.loadAllTasks();
            this.refreshService.triggerRefresh('pages');
          },
          (e) => { }
        );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '60%';
      dialogConfig.height = 'auto';
      dialogConfig.data = this.taskData;

      const dialogRef = this.dialog.open(
        RejectregularizeComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.loadAllTasks();
          this.refreshService.triggerRefresh('pages');
        },
        () => { }
      );
    }
  }

  approveOrRejectByCM(leaveApplID, status) {
    if (status == 'APPROVED') {
      const leaveApprove = {
        leaveApplicationId: leaveApplID,
        approvalStatus: 'APPROVED',
        reasonForRejection: '',
      };
      this.leaveApprovalService.update(leaveApprove).subscribe(
        (res) => {
          this.loadAllTasks();
          this.refreshService.triggerRefresh('pages');
        },
        () => { }
      );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '60%';
      dialogConfig.height = 'auto';
      dialogConfig.data = this.taskData;
      const dialogRef = this.dialog.open(RejectLeaveComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.loadAllTasks();
          this.refreshService.triggerRefresh('leave-summary');
          this.refreshService.triggerRefresh('pages');
        },
        () => { }
      );
    }
    this.loadAllTasks();
  }

  approveWfoByCM(trnx) {
    const id = trnx;
    if (id) {
      this.wfoService.approveWfoByCM(id).subscribe(
        (res) => {
          this.loadAllTasks();
          this.refreshService.triggerRefresh('working-from-office');
          this.refreshService.triggerRefresh('pages');
        },
        (e) => { }
      );
    } else {
    }
    this.loadAllTasks();
  }

  withdrawWfoByUser(trnx) {
    const id = trnx;

    if (id) {
      this.wfoService.withdrawWfoByUser(id).subscribe(
        (res) => {
          this.loadAllTasks();
        },
        (e) => { }
      );
    } else {
    }
  }

  async OpenProbationJSonForm() {
    await this.feedbackService.getProbationFeedBackByIdAsync(
      this.taskData.taskTransactionId
    );
    var resOfFeedBackAfterParse = await this.feedbackService
      .resOfProbationFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfProbationFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;

    await this.formSchema.getProbationFormSchemaAsync();
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    if (resOfFeedBackAfterParse.feedbackSubmissionStatus == 'READ') {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      this.taskData.taskTransactionId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      true,
      'PROBATION',
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed().subscribe(() => {
        this.loadAllTasks();
      });

  }

  async OpenReverseProbationJSonForm() {
    await this.feedbackService.getReverseProbationFeedBackByIdAsync(
      this.taskData.taskTransactionId
    );
    var resOfFeedBackAfterParse = await this.feedbackService
      .resOfProbationFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfProbationFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;

    await this.formSchema.getReverseProbationFormSchemaAsync();
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    temp = formSchemaAfterParse.formTemplate;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      this.taskData.taskTransactionId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      true,
      'REVERSE_PROBATION',
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed().subscribe(() => {
        this.loadAllTasks();
      });
  }

  declineReverseProbation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(DeclineFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.loadAllTasks();
    });
  }

  loadAllTasks() {
    this.store.dispatch(taskActions.loadTasks());
  }

  declineProbation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(DeclineFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.loadAllTasks();
    });
  }

  async OpenJSonForm() {
    await this.feedbackService.getFeedBackByIdAsync(
      this.taskData.taskTransactionId
    );
    var resOfFeedBackAfterParse = await this.feedbackService
      .resOfFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;
    await this.formSchema.getFormSchemaByRoleAndJobTitleAsync(
      resOfFeedBackAfterParse.feedbackRole,
      assinedUserAfterParse.jobTitle
    );
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    let feedbackType =
      this.taskData.typeOfTask == 'SUBMIT_REAL_TIME_FEEDBACK'
        ? 'ORPHAN_FEEDBACK'
        : 'FEEDBACK';
    if (resOfFeedBackAfterParse.status == 'READ') {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      this.taskData.taskTransactionId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      false,
      feedbackType,
      true,
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed().subscribe(() => {
        this.loadAllTasks();
      });
  }
  exitChecklist(trnx) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(ExitChecklistComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.loadAllTasks();
      },
      () => { }
    );
  }

  selectAction(type: string) {
    this.actions = this.TASK_ACTION_CONFIGURATION[type];
  }
  decline() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.taskData;
    const dialogRef = this.dialog.open(DeclineFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.loadAllTasks();
    });
  }
}
