import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';
import { Router } from '@angular/router';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-leave-summary',
  templateUrl: './leave-summary.component.html',
  styleUrls: ['./leave-summary.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class LeaveSummaryComponent implements OnInit {
  todayDate: Date = new Date();
  floaterMessage: string;
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  userId: string;
  leavebalanceData: any;
  paidLeave: number = 0;
  unplannedLeave: number = 0;
  lossOfPay: number = 0;
  compOff: number = 0;
  contigencyLeave: number = 0;
  paternityLeave: number = 0;
  restrictedHolidaysLeaves: number = 0;
  maternityLeave: number = 0;
  specialLeave: number = 0;
  totalAvailableBalance: number = 0;

  constructor(
    private userDetailService: UserDetailsService,
    private leaveBalanceService: LeaveBalanceService,
    private tabNavigationService: TabNavigationService,
    private router: Router,
    private refreshService: RefreshService
  ) { }

  ngOnInit(): void {
    this.refreshComponent()
  }

  refreshComponent() {
    let flag = true
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'leave-summary') {
        {
          this.getLeaveSummery();
          flag = false;
        }
      }
    });
    if (flag)
      this.getLeaveSummery();
  }

  getLeaveSummery() {
    this.userId = this.userDetailService.getUserDetails().id;
    this.getFloaterLeaveMessage();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.leaveBalanceService
      .getLeaveBalanceByIdAndYear(this.userId, currentYear)
      .subscribe((res) => {
        this.leavebalanceData = res.body.balanceLeave;
        this.paidLeave = this.leavebalanceData.PLANNED_LEAVE;
        this.unplannedLeave = this.leavebalanceData.UNPLANNED_LEAVE;
        this.lossOfPay = this.leavebalanceData.LOSS_OF_PAY;
        this.compOff = this.leavebalanceData.COMP_OFF;
        this.contigencyLeave = this.leavebalanceData.CONTINGENCY_LEAVE;
        this.paternityLeave = this.leavebalanceData.PATERNITY_LEAVE;
        this.restrictedHolidaysLeaves =
          this.leavebalanceData.FLOATER_LEAVE;
        this.maternityLeave = this.leavebalanceData.MATERNITY_LEAVE;
        this.specialLeave = this.leavebalanceData.SPECIAL_LEAVE;
        this.totalAvailableBalance =
          this.paidLeave +
          this.unplannedLeave +
          this.lossOfPay +
          this.compOff +
          this.contigencyLeave +
          this.paternityLeave +
          this.maternityLeave +
          this.restrictedHolidaysLeaves +
          this.specialLeave;
      });
  }

  getFloaterLeaveMessage() {
    const month = this.todayDate.getMonth() + 1;
    const isBetweenJanToJune = month >= 1 && month <= 6;
    if (isBetweenJanToJune) {
      this.floaterMessage = 'Lapse on June 30';
    } else {
      this.floaterMessage = 'Lapse on December 31';
    }
  }

  redirectToLeaveApply(typeOfLeave: string): void {
    this.router.navigate(['/leaveManagement/leave-apply'], { queryParams: { typeOfLeave: typeOfLeave } });
  }

  redirectToLeaveAvail() {
    this.router.navigate(['leaveManagement/holiday-calendar']);
  }
}
