import { ProbationFeedbackDTO } from 'src/app/dtos/ProbationFeedbackDTO';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackType } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProbationFeedbackGroupDTO } from 'src/app/dtos/ProbationFeedbackGroupDTO';
import {
  FeedbackRole,
  FeedbackTemplateCategory,
  ProbationFeedbackRole,
} from 'src/app/services/feedback/feedback-enum';
import { ToastrService } from 'ngx-toastr';
import { TalentService } from 'src/app/services/talent/talent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { NewProbationFeedbackGroupServiceService } from 'src/app/services/probation-ending/new-probation-feedback-group-service.service';
import { debuglog } from 'util';
@Component({
  selector: 'app-probation-dialog',
  templateUrl: './probation-dialog.component.html',
  styleUrls: ['./probation-dialog.component.scss'],
  providers: [PipeUnderScoreToSpace],
})
export class ProbationDialogComponent implements OnInit {
  public formList: FormArray;
  projects = [];
  FeedbackRole = [];
  FeedbackType = [];
  feedbackgroupdto = new ProbationFeedbackGroupDTO();
  feedbackgroupupdatedto = [];
  error: boolean = false;
  userNameIDMapping = {};
  userIdNameMapping = {};
  projectIDMapping = {};
  projectNameMapping = {};
  temp = {};

  selectedYear: number;
  selectedAreas: string[];
  isVisible = [];
  userId = [];
  years: any[] = [];
  projectList;
  feedbackOpinion: FormArray;
  feedbackarr: FormArray;
  feedbackDetails: FormGroup;
  proList;
  userList;
  performancevisible: boolean;
  userID;
  name;
  projectID;
  removedfeedbackopinion = [];
  selectedFormName: string;
  opiniondata: FormArray;
  emailError: string;
  flag: boolean = false;
  Error: string;
  uList;
  isEdit = true;
  firstName;
  displaySelectedArea = [];
  p = 0;
  currentYear;
  assinedManagerUseAfterParse: any;
  constructor(
    public dialogRef: MatDialogRef<ProbationDialogComponent>,
    public talentservice: TalentService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    private feedbackservice: FeedbackService,
    private userService: UserService,
    private projectService: ProjectService,
    private feedbackGroupService: NewProbationFeedbackGroupServiceService,
    public toastr: ToastrService,
    private user: UserService,
    private userGoalService: UserGoalService,
    @Inject(MAT_DIALOG_DATA) public directReporteeId
  ) {
    this.feedbackDetails = this.fb.group({
      feedbackType: ['', [Validators.required]],
      feedbackArray: this.fb.array([]),
    });
  }
  async ngOnInit() {
    await this.user.getUserByIdAsync(
      this.directReporteeId.directReporteeDetail.userId
    );
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    this.assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;

    this.userGoalService.getCurrentYear().subscribe((res) => {
      this.currentYear = res;
    });
    this.processEnumValuesToArray();

    if (Object.keys(this.directReporteeId).length != 4) {
      this.userService.getAllUserForDropDown().subscribe((usrData) => {
        usrData.body.forEach((element) => {
          this.userNameIDMapping[
            element.firstName +
              ' ' +
              element.lastName +
              '(' +
              element.employeeId +
              ')'
          ] = element.userID;
          this.userIdNameMapping[element.userID] =
            element.firstName +
            ' ' +
            element.lastName +
            '(' +
            element.employeeId +
            ')';
          this.temp[element.firstName + ' ' + element.lastName] =
            element.userID;
        });
        this.userList = Object.keys(this.userNameIDMapping);
        this.addComponent();
      });

      this.feedbackDetails.get('feedbackType').setValue('PROBATION');
      this.performancevisible = false;
    }
    this.selectedYear = new Date().getFullYear();
    for (
      let year = this.selectedYear - 1;
      year <= this.selectedYear + 1;
      year++
    ) {
      if ((this.directReporteeId.closedate.length = 0)) {
        for (let i of this.directReporteeId.closedate)
          if (year != i) {
            this.years.push(year.toString());
          }
      } else {
        this.years.push(year.toString());
      }
    }
    this.getuserList();
    this.setformdata();
  }
  search(query: string, i) {
    this.flag = true;
    let result = this.select(query);
    if (result.length != 0) {
      this.displaySelectedArea[i] = result;
    }
    this.displaySelectedArea[i] = result;
  }
  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.userList) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  focusOutEvent(i) {}

  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.feedbackDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.feedbackDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.feedbackDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  setOpinionFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string,
    i: number
  ) {
    this.feedbackarr = this.feedbackDetails.get('feedbackArray') as FormArray;
    this.feedbackarr.controls[i]
      .get(controlName)
      .markAsTouched({ onlySelf: true });
    this.feedbackarr.controls[i]
      .get(controlName)
      .markAsDirty({ onlySelf: true });
    this.feedbackarr.controls[i]
      .get(controlName)
      .setErrors({ [controlError]: true });
    this.Error = errorMsg;
  }

  setformdata() {
    if (this.directReporteeId.dataFeedback != null) {
      this.isEdit = false;
      this.performancevisible = true;

      this.feedbackDetails.get('feedbackType').setValue('Probation Feedback');
    } else {
      this.feedbackDetails.get('feedbackType').setValue('Probation Feedback');
    }
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  getprojectList() {
    if (Object.keys(this.directReporteeId).length != 4) {
      this.talentservice
        .getTalentByUserIdSubscribe(this.directReporteeId.directReporteeId)
        .subscribe((res) => {
          for (let r of res.body) {
            this.projectService
              .getProjectById(r.projectId)
              .subscribe((proList) => {
                this.projectIDMapping[proList.body.projectName] =
                  proList.body.id;
                this.projectNameMapping[proList.body.id] =
                  proList.body.projectName;
                this.projectList = Object.keys(this.projectIDMapping);
              });
          }
        });
    } else if (this.directReporteeId.dataFeedback != null) {
      this.talentservice
        .getTalentByUserIdSubscribe(
          this.directReporteeId.dataFeedback.feedbackFor
        )
        .subscribe((res) => {
          for (let r of res.body) {
            this.projectService
              .getProjectById(r.projectId)
              .subscribe((proList) => {
                proList.body.forEach((element) => {
                  this.projectIDMapping[element.projectName] = element.id;
                  this.projectNameMapping[element.id] = element.projectName;
                });
                this.projectList = Object.keys(this.projectIDMapping);
              });
          }
        });
    }
  }
  deleteFeedbackGroup() {
    this.feedbackGroupService
      .delete(this.directReporteeId.dataFeedback.id)
      .subscribe(
        (res) => {
          this.toastr.success('Successfully Deleted', '', {});
          window.location.reload();

          this.dialogRef.close();
        },
        (err) => {
          this.toastr.error('You cannot delete this group', err.error.title);
        }
      );
  }
  getProjectID(projectname) {
    this.projectID = this.projectIDMapping[projectname];
  }

  changedFieldType(index) {
    let validators = null;
    if (this.getFormGroup(index).controls['type'].value === 'email') {
      validators = Validators.compose([Validators.required, Validators.email]);
    } else {
      validators = Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp('^\\+[0-9]?()[0-9](\\d[0-9]{9})$')),
      ]);
    }
    this.getFormGroup(index).controls['value'].setValidators(validators);
    this.getFormGroup(index).controls['value'].updateValueAndValidity();
  }

  getFormGroup(index): FormGroup {
    const formGroup = this.formList.controls[index] as FormGroup;
    return formGroup;
  }

  createfeedbackDemo() {
    let formGroup = this.fb.group(new ProbationFeedbackDTO());
    formGroup.controls.feedbackFrom.setValidators([Validators.required]);
    formGroup.controls.feedbackRole.setValidators([Validators.required]);
    formGroup.controls.feedbackRole.setValue(null);
    return formGroup;
  }

  opinion(): FormArray {
    return this.feedbackDetails.get('feedbackArray') as FormArray;
  }

  addComponent() {
    this.displaySelectedArea;
    this.feedbackOpinion = this.feedbackDetails.get(
      'feedbackArray'
    ) as FormArray;
    this.isVisible.push(this.isVisible[this.isVisible.length - 1]);
    for (let i = this.isVisible.length - 1; i > 0; i--) {
      this.isVisible[i] = this.isVisible[i - 1];
    }
    this.isVisible[0] = false;

    this.displaySelectedArea.push(this.userList);
    this.feedbackOpinion.insert(0, this.createfeedbackDemo());
  }

  processEnumValuesToArray() {
    this.projects.push('Probation Feedback');

    for (let role of Object.values(ProbationFeedbackRole)) {
      if (isNaN(Number(role))) {
        this.FeedbackRole.push(role);
      }
    }
    for (let type of Object.values(FeedbackTemplateCategory)) {
      if (isNaN(Number(type))) this.FeedbackType.push(type);
    }
  }

  closeDialog() {
    this.dialogRef.close('close');
  }

  removefeedbackopinion(id, i: number) {
    this.removedfeedbackopinion.push(id);
    this.isVisible.splice(i, 1);

    this.opinion().removeAt(i);
  }

  checkError = (controlName: string, errorName: string) => {
    return this.feedbackDetails.controls[controlName].hasError(errorName);
  };

  getUserID(username, i) {
    var flag = this.reapeatOrNot(
      this.feedbackOpinion.controls[i].value.feedbackRole,
      i,
      username
    );
    for (let feedback of this.feedbackDetails.value.feedbackArray) {
      feedback.feedbackFrom =
        this.userNameIDMapping[feedback.feedbackFrom] ||
        this.temp[feedback.feedbackFrom];
    }
    this.userID = this.userNameIDMapping[username] || this.temp[username];
    this.displaySelectedArea[i] = this.userList;
    if (flag == true) {
      this.toastr.warning('Already exits', 'failed', {});
    }
  }

  getUserName(id) {
    this.name = this.userIdNameMapping[id];
    return this.name;
  }

  getuserList() {
    this.userService.getAllUserForDropDown().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        if (element.userID != this.directReporteeId.directReporteeId) {
          this.userNameIDMapping[
            element.firstName +
              ' ' +
              element.lastName +
              '(' +
              element.employeeId +
              ')'
          ] = element.userID;
          this.userIdNameMapping[element.userID] =
            element.firstName +
            ' ' +
            element.lastName +
            '(' +
            element.employeeId +
            ')';
          this.temp[element.firstName + ' ' + element.lastName] =
            element.userID;
        }
      });

      this.userList = Object.keys(this.userNameIDMapping);
      let i = 0;
      this.selectedAreas = this.userList;
      if (this.directReporteeId.dataFeedback != null) {
        if (this.directReporteeId.dataFeedback.feedbackFrom != null) {
          this.addComponent();
          this.opiniondata = this.feedbackDetails.get(
            'feedbackArray'
          ) as FormArray;
          this.opiniondata.clear();
          for (let s of this.directReporteeId.dataFeedback.feedbackFrom) {
            this.isVisible[this.p] = true;
            this.p++;
            this.opiniondata.push(this.fb.group(s));
            this.displaySelectedArea.push(this.selectedAreas);
          }
        }
      }

      usrData.body.forEach((element) => {});

      this.uList = Object.keys(this.temp);
    });
  }

  onSubmit() {
    if (this.feedbackDetails.valid) {
      this.feedbackgroupdto.createdBy =
        this.userDetailService.getUserDetails().id;
      this.feedbackgroupdto.feedbackType = FeedbackType.PROBATION;
      if (this.directReporteeId.dataFeedback != null) {
        this.feedbackgroupdto.feedbackFor =
          this.directReporteeId.dataFeedback.feedbackFor;
      } else {
        this.feedbackgroupdto.feedbackFor =
          this.directReporteeId.directReporteeId;
      }
      this.feedbackgroupdto.year = new Date().getFullYear().toString();

      for (let i of this.removedfeedbackopinion) {
        this.feedbackservice.deleteProbation(i).subscribe((res) => {});
      }
      for (let feedback of this.feedbackDetails.value.feedbackArray) {
        var t =
          this.userNameIDMapping[feedback.feedbackFrom] ||
          this.temp[feedback.feedbackFrom];
        feedback.feedbackFrom = t === undefined ? feedback.feedbackFrom : t;
        if (feedback && !feedback.id) {
          this.feedbackgroupdto.feedbackFrom.push(feedback);
          if (feedback.feedbackRole == 'SELF_EVALUATION') {
            feedback.feedbackFrom = this.directReporteeId.directReporteeId;
          } else if (feedback.feedbackRole == 'REVERSE_PROBATION') {
            feedback.feedbackFrom = this.directReporteeId.directReporteeId;
          }
          this.feedbackgroupupdatedto.push(feedback);
        }
      }
      if (this.directReporteeId.dataFeedback != null) {
        this.feedbackGroupService
          .update(
            this.feedbackgroupupdatedto,
            this.directReporteeId.dataFeedback.id
          )
          .subscribe(
            (res) => {
              this.toastr.success('Successfully uploaded', 'Saved', {});
              this.dialogRef.close();
            },
            (err) => {
              this.toastr.success('Please try again', '', {});
            }
          );
      } else {
        this.feedbackGroupService.create(this.feedbackgroupdto).subscribe(
          (res) => {
            this.toastr.success('Successfully uploaded', 'Saved', {});
            this.dialogRef.close();
          },
          (err) => {
            this.toastr.success(
              'Feedback alredy initialised for this user',
              '',
              {}
            );
          }
        );
      }
    } else {
      this.feedbackDetails.markAllAsTouched();

      this.toastr.error('Enter all required fields', 'Error', {});
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  selectedRole(role, i) {
    this.directReporteeId;
    var temp =
      this.directReporteeId.directReporteeDetail.firstName +
      ' ' +
      this.directReporteeId.directReporteeDetail.lastName +
      '(' +
      this.directReporteeId.directReporteeId +
      ')';
    var temp2 =
      this.assinedManagerUseAfterParse.firstName +
      ' ' +
      this.assinedManagerUseAfterParse.lastName +
      '(' +
      this.assinedManagerUseAfterParse.employeeId +
      ')';

    if (role == 'SELF_EVALUATION') {
      this.feedbackOpinion.controls[i].setValue({
        feedbackFrom: temp,
        feedbackRole: 'SELF_EVALUATION',
        feedbackSubmissionStatus: 'CREATED',
        updatedAt: new Date(),
      });
      this.isVisible[i] = true;

      this.displaySelectedArea[i] = this.userList;
    } else if (role == 'CAREER_MANAGER') {
      this.feedbackOpinion.controls[i].setValue({
        feedbackFrom: temp2,
        feedbackRole: 'CAREER_MANAGER',
        feedbackSubmissionStatus: 'CREATED',
        updatedAt: new Date(),
      });
      this.isVisible[i] = true;
      this.displaySelectedArea[i] = this.userList;
    }
    var flag = this.reapeatOrNot(
      role,
      i,
      this.feedbackOpinion.controls[i].value.feedbackFrom
    );

    if (!flag) {
    } else {
      this.toastr.warning('Already exist', 'failed', {});
    }
  }

  checkRole(role) {
    if (role == 'SELF_EVALUATION') {
      return true;
    }
    if (role == 'CAREER_MANAGER') {
      return true;
    }
    return false;
  }

  reapeatOrNot(role, i, name) {
    var flag = false;
    var count = 0;

    for (let feedback of this.feedbackDetails.value.feedbackArray) {
      var feedbackFor =
        this.userNameIDMapping[
          this.feedbackOpinion.controls[i].value.feedbackFrom
        ] || this.temp[this.feedbackOpinion.controls[i].value.feedbackFrom];
      if (role == 'SELF_EVALUATION') {
        feedbackFor =
          this.temp[
            this.feedbackOpinion.controls[i].value.feedbackFrom.split('(')[0]
          ];
      }
      if (
        (feedbackFor == feedback.feedbackFrom ||
          name.split('(')[0] == feedback.feedbackFrom) &&
        (role == feedback.feedbackRole ||
          this.feedbackOpinion.controls[i].value.feedbackRole ==
            feedback.feedbackRole) &&
        count != 0
      ) {
        flag = true;
        this.removefeedbackopinion(undefined, 0);
        break;
      }
      count++;
    }
    return flag;
  }
}
