<div class="main_header_div">
    <h3 mat-dialog-title class="mat-title"><img class="header-icon mr-3" src="assets/icons/rtf.png">Delete real-time feedback</h3>
    <div class="pr-3">
        <img class="close-icon" (click)="closeDialog()" src="assets/icons/close.png">
    </div> 
</div>
<hr/>
<div>
    <div>
        <p class="paragraph">Are you sure you want to delete this real-time feedback?</p>
    </div>

  <div>
    <button class="cancel" (click)="closeDialog()">Cancel</button>
    <button class="delete" (click)="deleteFeedback()">Delete</button>
  </div>
</div>
