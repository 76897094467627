export const environment = {
  production: true,
  //dev server urls
  BASE_API_URL_BACKEND: 'https://pulse.bluealtair.dev:8080',
  BASE_API_URL_FRONTEND: 'https://pulse.bluealtair.dev'

  // //production urls
//   BASE_API_URL_BACKEND: 'https://pulse.bluealtair.com:8080',
//   BASE_API_URL_FRONTEND: 'https://pulse.bluealtair.com'
};

