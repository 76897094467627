import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
@Component({
  selector: 'app-delete-feedback',
  templateUrl: './delete-feedback.component.html',
  styleUrls: ['./delete-feedback.component.scss'],
})
export class DeleteFeedbackComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private feedbackService: FeedbackService,
    private tosterService: ToastrService,
    private dialogRef: MatDialogRef<DeleteFeedbackComponent>
  ) { }
  ngOnInit(): void {
  }
  deleteFeedback() {
    this.feedbackService.delete(this.data).subscribe(
      (res) => {
        this.feedbackService.filter('');
        this.tosterService.success('Feedback deleted!');
        this.dialogRef.close();
      },
      (e) => {
        this.tosterService.warning('You cannot delete this feedback!');
        this.dialogRef.close();
      }
    );
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
