import { HomeComponent } from "src/app/components/home/home.component";
export enum JobTitles {
    INTERN = "Intern",
    ANALYST = "Analyst",
    CONSULTANT = "Consultant",
    SR_CONSULTANT = "Sr. Consultant",
    MANAGER = "Manager",
    SR_MANAGER = "Sr. Manager",
    DIRECTOR = "Director",
    SR_DIRECTOR = "Sr. Director",
    MANAGING_DIRECTOR = "Managing Director",
    CEO = "CEO"
}
export enum ExcelSheetName 
{
 NewEmployee="NewEmployees.xlsx",
 ActiveEmployee="ActiveEmployees.xlsx",
 AbsentEmployee="AbsentEmployees.xlsx",
 OnLeaveEmployee="OnLeaveEmployees.xlsx",
 AlumniEmployee="AlumniEmployees.xlsx"
}
export enum CourseTypes {
    HigherSecondary = 'Higher Secondary',
    AssociateDegree = 'Associate Degree',
    BachelorsDegree = 'Bachelor\'s Degree',
    MastersDegree = 'Master\'s Degree',
    DoctoralDegree = 'Doctoral Degree (Ph.D.)',
    Diploma = 'Diploma',
    Certificate = 'Certificate',
    VocationalTraining = 'Vocational Training',
    HighSchoolEquivalency = 'High School Equivalency',
    ContinuingEducation = 'Continuing Education',
    Other = 'Other'
}
export enum AuthoritiesConstants {
    ROLE_ADMIN = "Role Admin",
    ROLE_USER = "Role User",
    ROLE_CAREER_MANAGER = "Role Career Manager",
    ROLE_SUPER_ADMIN = "Role Super Admin",
    ROLE_ASSET_ADMIN = "Role Asset Admin",
    ROLE_PROJECT_ADMIN = "Role Project Admin",
    ROLE_PROJECT_CLIENT = "Role Project Client",
    ROLE_EMPLOYEE_ADMIN = "Role Employee Admin",
    ROLE_CLIENT_ADMIN = "Role Client Admin",
    ROLE_HUMAN_RESOURCE = "Role Human Resource",

}
export enum Roles
{
    ROLE_ADMIN="ROLE_ADMIN",
    ROLE_USER="ROLE_USER",
    ROLE_CAREER_MANAGER="ROLE_CAREER_MANAGER",
    ROLE_SUPER_ADMIN="ROLE_SUPER_ADMIN",
    ROLE_ASSET_ADMIN="ROLE_ASSET_ADMIN",
    ROLE_PROJECT_ADMIN="ROLE_PROJECT_ADMIN",
    ROLE_PROJECT_CLIENT="ROLE_PROJECT_CLIENT",
    ROLE_EMPLOYEE_ADMIN="ROLE_EMPLOYEE_ADMIN",
    ROLE_CLIENT_ADMIN="ROLE_CLIENT_ADMIN",
    ROLE_HUMAN_RESOURCE="ROLE_HUMAN_RESOURCE"
}
export enum Departments {
    API_MANAGEMENT_AND_INTEGRATION = "API Management & Integration",
    CLIENT_SUCCESS = "Client Success",
    DIGITAL_APPLICATION_DEVELOPMENT = "Digital Application Development",
    DATA_SCIENCE_AND_AI = "Data Science & AI",
    DATA_MANAGEMENT = "Data Management",
    SUPPORT_FUNCTIONS = "Support Functions",
    GEOGRAPHY = "Geography",
}
export enum RelationshipStatus {
    UNMARRIED = "Unmarried",
    MARRIED = "Married",
    SEPARATED = "Separated",
    OTHER="Other"
}
export enum BloodGroup {
    A_POSITIVE = "A+",
    A_NEGATIVE = "A-",
    B_POSITIVE = "B+",
    B_NEGATIVE = "B-",
    AB_POSITIVE = "AB+",
    AB_NEGATIVE = "AB-",
    O_POSITIVE = "O+",
    O_NEGATIVE = "O-"
}
export enum Gender {
    MALE = "Male",
    FEMALE = "Female",
    OTHERS = "Others"
}
export enum NomineeStatus {
    Yes,
    No
}
export enum Relation {
    Brother,
    Sister,
    Mother,
    Father,
    Spouse,
    Other
}
export enum CustodianType {
    Spouse,
    Guardian
}
export enum ProbationPeriod {
    Sixty = "60",
    Ninety = "90"
}
export enum ProjectRole {
    PROJECT_MANAGER, SOLUTION_ARCHITECT, SUBJECT_MATTER_EXPERT, BUSINESS_ANALYST, DEVELOPER, QA_ENGINEER
}
export enum ProjectCategory {
    BILLABLE, NON_BILLABLE
}

export enum ProjectType {
    Internal, External
}
export enum FeedbackType{
    PROJECT="PROJECT_REVIEW_FEEDBACK",ADHOC="REGULAR_FEEDBACK", PROBATION="PROBATION",
    // ORPHAN_FEEDBACK = 'ORPHAN_FEEDBACK' ,
    // ORPHAN='REAL_TIME_FEEDBACK'
}

export enum TypeOfTask {
    PROBATION_FEEDBACK = "PROBATION_FEEDBACK",
    REVERSE_PROBATION_FEEDBACK = "REVERSE_PROBATION_FEEDBACK"
}

export enum GeoLocation {
    INDIA = "India",
    USA = "USA",
    AUSTRALIA="Australia"
}

export enum LeaveType {
    FLOATER_LEAVE = "FLOATER_LEAVE",
    PLANNED_LEAVE = "PLANNED_LEAVE",
    UNPLANNED_LEAVE = "UNPLANNED_LEAVE",
    PATERNITY_LEAVE = "PATERNITY_LEAVE",
    MATERNITY_LEAVE = "MATERNITY_LEAVE",
    CONTINGENCY_LEAVE = "CONTINGENCY_LEAVE",
    COMP_OFF = "COMP_OFF",
    SPECIAL_LEAVE = "SPECIAL_LEAVE",
    LOSS_OF_PAY = "LOSS_OF_PAY",
    ACCRUED_LEAVE = "ACCRUED_LEAVE"
}
export enum TransactionType
{
    ENCASH ="ENCASH",
    CARRY_FORWARD_DEBIT="CARRY_FORWARD_DEBIT",
    SYSTEM="SYSTEM",
    CREDIT="CREDIT",
    HR_CREDIT="HR_CREDIT",
    HR="HR",
    DEBIT="DEBIT",
    USER="USER",
    AVAILED="AVAILED",
    WITHDRAWN="WITHDRAWN",
    OPENING_BALANCE="OPENING_BALANCE",
}

export enum FirstnSecondHalf {
    First_Half = 1,
    Second_Half = 2
}

export enum EventTabClosed{
  Closed="Closed"
}
export enum Status
{
    Inactive="Inactive",
    Active="Active"
}

export enum AddressType
{
    CURRENT="CURRENT",
    PERMANENT="PERMANENT"
}
export enum ContactType
{
    Emergencycontacttwo="Emergencycontacttwo",
    Emergencycontactone="Emergencycontactone",
}
export enum Status
{
    VALID="VALID",
    PENDING="PENDING",
    READ="READ",
    CREATED='CREATED',
    SUBMITTED='SUBMITTED',
    DRAFT='DRAFT',
    APPLIED='APPLIED',
    REJECTED='REJECTED',
    KNOWLEDGE_TRANSFER_STARTED='KNOWLEDGE_TRANSFER_STARTED',
    ACCEPTED='ACCEPTED',
    APPROVED='APPROVED',
    REVOKE_REQUEST_APPROVED="REVOKE_REQUEST_APPROVED",
    REVOKED_BY_USER="REVOKED_BY_USER"

}
export enum HolidayType
{
    FLOATER_HOLIDAY="FLOATER_HOLIDAY",
    MANDATORY_HOLIDAY="MANDATORY_HOLIDAY"
}

export enum  EmployementType
{
    CONTRACTOR="Contractor",
    PERMANENT="Permanent"
}