import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AuthoritiesConstants, Departments, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { __await } from 'tslib';
import { SpotLightComponent } from './spot-light/spot-light.component';
import { Subscription } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddRealTimeFeedbackComponent } from './add-real-time-feedback/add-real-time-feedback.component';
import { RealTimeFeedbackComponent } from './public-profile/real-time-feedback.component';
import { ToastrService } from 'ngx-toastr';
import { AddNewEmployeeCardComponent } from '../employee-config/add-new-employee-card/add-new-employee-card.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-profile-employee',
  templateUrl: './profile-employee.component.html',
  styleUrls: ['./profile-employee.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ],
})
export class ProfileEmployeeComponent implements OnInit {
  @ViewChild(SpotLightComponent) spotLightComponent: SpotLightComponent;
  @ViewChild(RealTimeFeedbackComponent) publicProfileComponent: RealTimeFeedbackComponent;
  user_details = new UserDTO();
  imageform: FormGroup;
  isrelieved: Boolean;
  hasRoleBased: Boolean;
  userid: string;
  department = Departments
  jobTitle = JobTitles;
  joiningdate: Date;
  isSpinner = true;
  userDto: UserDTO;
  imageError: string;
  photo: any;
  totalDaysinBA;
  saved: Boolean = false;
  careermanager: string;
  loggedInProfile: Boolean;
  maindata = 0;
  userDetails: UserDTO;
  authorities = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  private dataSubscription: Subscription;
  loggedInUserId: any;
  userImageThumbnail: string;
  matTabsClickedIndex: any;
  overAllExperince:any=0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private toaster: ToastrService
  ) {
       this.feedbackService.sendData(this.route.snapshot.paramMap.get('id')? this.route.snapshot.paramMap.get('id'): this.loggedInUserId);
  }
  ngOnInit() {
    this.refreshOnSearchOfEmployee()
  }
  refreshOnSearchOfEmployee()
  {
    this.dataSubscription = this.feedbackService.data$.subscribe((data) => {
      this.userid = data;
      if (data ==null) {
        this.setUserId();
      }
      this.initiateProfileComponent()
      this.getUserbyId();
      this.setIsRelieved();
      this.setFormData();
    });
    this.imageform = this.fb.group({ imageBase: [''] });
  }
  initiateProfileComponent() {
    this.loggedInUserId = this.userDetailService.getUserDetails().id;
   this.setUserId();
    this.hasRoleBased = this.userDetailService.hasRole([
      this.getKeyByValue(AuthoritiesConstants.ROLE_HUMAN_RESOURCE).toString(),
      this.getKeyByValue(AuthoritiesConstants.ROLE_SUPER_ADMIN).toString()
    ]);
    this.userService;
    this.department = Departments;
    this.jobTitle = JobTitles;
    this.maindata = 0;
    this.isSpinner = true;
  }
  getKeyByValue(value: string): string | undefined {
    return Object.keys(AuthoritiesConstants).find(key => AuthoritiesConstants[key] === value);
}
  setUserId()
  {
    if (this.userid == null) {
      this.userid = this.route.snapshot.paramMap.get('id')? this.route.snapshot.paramMap.get('id'): this.loggedInUserId;
      this.loggedInProfile = (this.userid === this.loggedInUserId);

    } else {
      this.loggedInProfile = (this.userid === this.loggedInUserId);
    }
  }
  setIsRelieved() {
    if (this.userid != null && (this.loggedInProfile || this.hasRoleBased)) {
      this.userService.getUserRelievedStatus(this.userid).subscribe((res) => {
        this.isrelieved = res.body;
      });
    }
  }
  getUserbyId() {
    this.userService.getUserByID(this.userid).subscribe((res) => {
      this.authorities = [];
      this.authorities = res.body.authorities;
      this.user_details = res.body;
      this.userDto = res.body;
      this.joiningdate = this.userDto.joiningDate;
      this.isSpinner=false;
      if (this.userDto.userImageThumbnail !== '') {
        this.userDto.userImageThumbnail = 'data:image/png;base64,' + this.userDto.userImageThumbnail;
      }
      this.userService.getUserByID(this.userDto.careerManagerId).subscribe(
        (res) => {
          this.careermanager = res.body.firstName + ' ' + res.body.lastName;
        },
        (e) => {
          this.careermanager = 'Not Available';
          if (this.loggedInProfile || this.hasRoleBased)
            this.toaster.warning(
              'Career Manager details are not available for ' +
              this.user_details.firstName +
              '! Please contact Admin'
            );
        }
      );
    },
      (e) => {
            this.userService.getDeactivatedUserById(this.userid).subscribe((res) => {
          this.authorities = [];
          this.authorities = res.body.authorities;
          this.user_details = res.body;
          this.userDto = res.body;
          this.joiningdate = this.userDto.joiningDate;
          this.isSpinner=false;
          if (this.userDto.userImageThumbnail !== '') {
            this.userDto.userImageThumbnail  = 'data:image/png;base64,' + this.userDto.userImageThumbnail;
          }
          this.userService.getUserByID(this.userDto.careerManagerId).subscribe(
            (res) => {
              this.careermanager = res.body.firstName + ' ' + res.body.lastName;
            },
            (e) => {
              this.careermanager = 'Not Available';
              if (this.loggedInProfile || this.hasRoleBased)
                this.toaster.warning(
                  'Career Manager details are not available for ' +
                  this.user_details.firstName +
                  '! Please contact Admin'
                );
            }
          );
        },e=>{
          this.router.navigateByUrl('/page-not-found');
        }
        );
      }
    );
  }
  
  changeRelievedStatus() {
    this.userService
      .changeRelievedStatus(this.user_details.id)
      .subscribe(() => {
        this.ngOnInit();
      });
  }
  setFormData() {
    if (this.loggedInProfile) {
      this.userDetails = null;
      this.userDetails = this.userDetailService.getUserDetails();
      if (this.userDetails.userImageThumbnail !== '') {
        this.photo = true;
      } else {
        this.photo = false;
      }
    } else {
      this.userDto = this.user_details;
    }
  }

  formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return 'Not Available';
    }
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    if (numericPhoneNumber.length >= 10) {
      const countryCode = numericPhoneNumber.slice(
        0,
        numericPhoneNumber.length - 10
      );
      const localNumber = numericPhoneNumber.slice(
        numericPhoneNumber.length - 10
      );
      return `+${countryCode} ${localNumber}`;
    } else {
      return 'Not Available';
    }
  }
  calculateOverallExperience(): string {
    let totalExperienceMonths = 0;
    const startDate = new Date(this.user_details.joiningDate);
    const endDate = new Date();
    const experienceDuration = this.calculateExperienceDuration(
      startDate,
      endDate
    );
    totalExperienceMonths += experienceDuration;
    const totalExperienceYears = Math.floor(totalExperienceMonths / 12);
    const remainingExperienceMonths = totalExperienceMonths % 12;
    let result =
      totalExperienceYears > 0 ? `${totalExperienceYears} years ` : '';
    if (remainingExperienceMonths > 0) {
      result += `${Math.round(remainingExperienceMonths)} months`;
    }
    return result;
  }
  private calculateExperienceDuration(startDate: Date, endDate: Date): number {
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    const diffInMonths = diffInMilliseconds / (1000 * 60 * 60 * 24 * 30.44);
    return diffInMonths;
  }
  getUserData(userid) {
    this.userService.getUserByID(userid).subscribe(
      (res) => {
        this.userDto = res.body;
        this.isSpinner=false;
      },
      (e) => {
        this.toaster.error(
          'Something went wrong. Please re-login and then try again'
        );
      }
    );
  }
  AssignRealTimeFeedback(userDetails) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = 'dialog-class';
    dialogConfig.data = userDetails.id;
    const dialogRef = this.dialog.open(
      AddRealTimeFeedbackComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.publicProfileComponent.getFeedbackForOtherUsersProfile();
    });
  }
  editCurrentEmployee() {
    this.getUserData(this.userid);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '75%';
    dialogConfig.data = [this.userDto,'active-user'];
    const dialogRef = this.dialog.open(AddNewEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getUserData(this.userid);
      this.isSpinner=false;
      this.ngOnInit();
    });
  }
}
