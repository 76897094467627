import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { ApiService } from './api.service';
import { SidenavService } from './sidenav.service';
import { StorageService } from './storage.service';
import { UserDetailsService } from './user-details.service';
interface Page {
  link: string;
  name: string;
  icon: string;
  tooltip: string;
  display: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public pages: Page[];
  isLoggedIn = false;
  loggedInUser;
  jwt;
  isAdmin = false;
  isEmployeeAccessible = false;
  isProjectManagerAccessible = false;
  isProjectSupervisorAccessible = false;
  isCareerManagerAccessible = false;
  response;
  response1;
  response2;
  isLoggedOut = false;
  authorities = [];
  constructor(private apiService: ApiService,
    private storageService: StorageService,
    private messageService: MessageService,
    private router: Router,
    private _sidenavService: SidenavService,
    private msalService: MsalService, private userDetailsService: UserDetailsService) {
    this.loggedInUser = this.storageService.getItem(StorageService.LOGGED_IN_USER_INFO);
  }
  updateLoggedinUserInfo() {
    this.storageService.setItem(StorageService.IS_LOGGED_OUT, "false");
    let loggedInUser = this.storageService.getItem(StorageService.LOGGED_IN_USER_INFO);
    if (loggedInUser != null) {
      this.loggedInUser = JSON.parse(loggedInUser);
    }
    if (this.loggedInUser && this.loggedInUser.role == 'ADMIN') {
      this.isAdmin = true;
      this.isEmployeeAccessible = true;
    } else if (this.loggedInUser && this.loggedInUser.role == 'CM') {
      this.isEmployeeAccessible = true;
    }
    this.pages = [
      { name: 'Home', link: '/home', icon: 'home', tooltip: 'Home page', display: this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER","ROLE_USER"]) },

      { name: 'Employees', link: '/employee', icon: 'account_circle', tooltip: 'Employees page', display: this.userDetailsService.hasRole([ "ROLE_ADMIN"]) },
      { name: 'Project', link: '/project', icon: 'assignment', tooltip: 'Project', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'Feedback form', link: '/formGeneration', icon: 'insert_drive_file', tooltip: 'Generate Feedback Forms', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'Performance', link: '/careerManager', icon: 'groups', tooltip: 'Career Manager', display: this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER","ROLE_ADMIN","ROLE_USER"]) },
      { name: 'My_Tasks', link: '/feedback-submission', icon: 'insert_chart', tooltip: 'Feedback Submission', display: this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER","ROLE_USER"]) },

      { name: 'Feedback form', link: '/formGeneration', icon: 'insert_drive_file', tooltip: 'Generate Feedback Forms', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'Performance', link: '/careerManager', icon: 'trending_up', tooltip: 'Career Manager', display: this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER","ROLE_ADMIN","ROLE_USER"]) },
      { name: ' Leave', link: '/project', icon: 'calendar_today' , tooltip: 'Project', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'Notification', link: '/project', icon: 'notifications' , tooltip: 'Project', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'help', link: '/project', icon: 'help' , tooltip: 'Project', display: this.userDetailsService.hasRole(["ROLE_ADMIN"]) },
      { name: 'profiles',link :'/myprofile',icon: 'settings' , tooltip: 'Project', display: this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER","ROLE_ADMIN","ROLE_USER"]) },
    

    ]
  }
  loginWithPassword(email) {
    this.apiService.getEmployeeByEmail(email).subscribe(res => {
      if (res != null) {
        this.loggedInUser = res;
        this.isLoggedIn = true;
        this.storageService.setItem(StorageService.LOGGED_IN_USER_INFO, JSON.stringify(res));
        if (this.loggedInUser.role == 'EMP') {
          this.router.navigate(['feedback']);
        } else {
          this.router.navigate(['employees']);
        }
        this.updateLoggedinUserInfo();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Invalid Credentials', detail: 'Please try again' });
      }
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Invalid Credentials', detail: 'Please try again' });
    })
  }
  loginGetToken(loginPass) {
    this.apiService.getAuthenticationToken(loginPass).subscribe(res => {
      this.jwt = res["body"]["id_token"];
      const refreshToken = res.body.refresh_token;
      this.storageService.setItem(StorageService.JWT_TOKEN, this.jwt)
      this.storageService.setItem(StorageService.REFRESH_TOKEN, refreshToken)
      var authorityList = this.getAuthority();
      if (res != null) {
        this.loggedInUser = res;
        this.isLoggedIn = true;
        this.storageService.setItem(StorageService.LOGGED_IN_USER_INFO, JSON.stringify(res));
        this.updateLoggedinUserInfo();
      }
    })
  }
  addNewProject(projectData) {
    this.apiService.postProjectData(projectData).subscribe()
  }
  updateProject(projectData) {
    this.apiService.putProjectData(projectData).subscribe()
  }
  updateCareerManagerTeam(CareerManagerTeam) {
    this.apiService.putCareerManagerTeamData(CareerManagerTeam).subscribe()
  }
  updateFeedbackForm(formData) {
    this.apiService.putFormData(formData).subscribe()
  }
  addNewFormSchema(formData) {
    this.apiService.postProjectFormData(formData).subscribe()
  }
  addNewFormCareerManagerSchema(formData) {
    this.apiService.postCareerManagerFormData(formData).subscribe()
  }
  getAllProjects() {
    this.apiService.getAllProjects(this.jwt).subscribe(res => {
      this.response1 = JSON.stringify(res);
      return this.response1;
    })
  }
  getAuthority() {
    this.apiService.getAuthority(this.jwt).subscribe(res => {
      let authorityList = JSON.stringify(res);
      if (authorityList.match('ROLE_ADMIN')) {
        this.router.navigate(['create']);
      }
      else if (authorityList.match('ROLE_USER')) {
      }
      var temp = [];
      temp = JSON.parse(authorityList);
    })
  }
  logout() {
    this.isLoggedIn = false;
    this.isLoggedOut = true;
    this.loggedInUser = null;
    this.storageService.setItem(StorageService.IS_LOGGED_OUT, "true");
    this.storageService.removeItem(StorageService.ACCESS_TOKEN);
    this.storageService.removeItem(StorageService.LOGGED_IN_USER_INFO);
    this.storageService.removeItem(StorageService.JWT_TOKEN);
    this.storageService.removeItem(StorageService.REFRESH_TOKEN);
    location.reload();
  }
}
function getAllProjects(jwt: any) {
  throw new Error('Function not implemented.');
}
