<div>
  <div class="search_main mt-0.5">
    <img src="assets/icons/search-black.png">
    <input
      class="search_input"
      [matAutocomplete]="userAuto"
      type="search"
      placeholder="Employee Search"
      [formControl]="userControl"
    />
    <mat-autocomplete
      #userAuto="matAutocomplete"
      (optionSelected)="selectUser($event.option.value)"
    >
      <mat-option
        *ngFor="
          let user of filteredUsers
            | async
            | filter : userControl.value
            | orderBy : 'employeeId'
        " [value]="user">
        <span class="search-img">
          <img class="search_employee_image" src="assets/icons/default-avatar.jpg" alt="" *ngIf="(this.user?.userImageThumbnail == '')"/>
          <img class="search_employee_image" [(src)]="this.user.userImageThumbnail" alt="" *ngIf="(this.user?.userImageThumbnail !== '')"/>
          {{
          user.firstName + " " + user.lastName + " (" + user.employeeId + ")"
          | titlecase
          }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>