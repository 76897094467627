<div>
  <div *ngIf="isSpinner" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>
  <div class="container" *ngIf="!isSpinner">
    <div class="row">
      <div class="col-md-3 search-bar mt-3">
        <img class="col-md-2 icon" src="\assets\svg\search_bar.svg" alt="search" />
        <input type="search" class="search-input col-md-10" placeholder="Search " [(ngModel)]="searchTerm"
          (input)="applySearch()" />
      </div>
      <div class="col-md-1 btn mt-3">
        <div class="row">
          <div class="col-md-6 centered-content" [matTooltipPosition]="position.value"
            [matTooltipClass]="position.value" matTooltip="Add New Employee" (click)="addNewEmployee()">
            <img class="img-class-bulk" src="assets/svg/addemp.svg" />
          </div>
          <div class="col-md-6 centered-content" [matTooltipPosition]="position.value"
            [matTooltipClass]="position.value" matTooltip="Bulk Upload">
            <img class="img-class-bulk" src="assets/svg/bulk-import.svg" />
          </div>
        </div>
      </div>
      <div class="col-md-2 mat-form-field-capabilities mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Capabilities</span>
          </mat-label>
          <mat-select [(ngModel)]="capabilitySearch" (selectionChange)="applySearchForJobTitleAndDepartement()"
            multiple>
            <mat-option *ngFor="let capability of this.departments" [value]="capability"
              [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              [matTooltipDisabled]="!ifOverflow(cap)" matTooltip="{{ capability }}">
              <div class="options" #cap>{{ capability }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2 mat-form-field-title mt-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <img src="\assets\svg\filter-icon.svg" />
            <span class="common-heading"> Title</span>
          </mat-label>
          <mat-select [(ngModel)]="jobTitleSearch" (selectionChange)="applySearchForJobTitleAndDepartement()" multiple>
            <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" [matTooltipPosition]="position.value"
              [matTooltipClass]="position.value" [matTooltipDisabled]="!ifOverflow(t)" matTooltip=" {{ jobTitle }}">
              <div class="options" #t>{{ jobTitle }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <pagination-controls class="pagination-controls mt-3" (pageChange)="page = $event" previousLabel="<"
        nextLabel=">"></pagination-controls>
    </div>
    <div class="row active-deactive mt-3" *ngIf="this.selectedUsers.length > 0">
      <div class="left-div">
        <button class="btn-activate active-color" (click)="activateSelectedUsers()">
          Activate
        </button>
        <button class="btn-delete delete-color" (click)="deleteSelectedUsers()">
          Delete
        </button>
      </div>
    </div>

    <div class="mt-2 row">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="Name"
                [matTooltipDisabled]="!ifOverflow(headerN)" #headerN>
                <div class="table-header-cell">
                  <input type="checkbox" [checked]="this.isChecked" class="check-box-all"
                    (change)="toggleSelectAll()" />
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon exception"
                    (click)="sortTable('name')" />
                  <span class="name">Name</span>
                </div>
              </th>
              <th colspan="2" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Title" [matTooltipDisabled]="!ifOverflow(headerT)" #headerT>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" (click)="sortTable('jobTitle')" />
                  <span class="name">Title</span>
                </div>
              </th>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Capability" [matTooltipDisabled]="!ifOverflow(headerC)" #headerC>
                <div class="table-header-cell">
                  <img src="assets/svg/sort.svg" alt="Double Arrow" class="icon" (click)="sortTable('department')" />
                  <span class="name">Capability</span>
                </div>
              </th>
              <th colspan="2" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Date of Joining" [matTooltipDisabled]="!ifOverflow(headerDoj)" #headerDoj>
                Date of Joining
              </th>
              <th colspan="3" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip="Official Email ID" [matTooltipDisabled]="!ifOverflow(headerE)" #headerE>
                Official Email ID
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let newUser of this.filteredUsers
                  | paginate : { itemsPerPage: 10, currentPage: page }
              ">
              <td colspan="3">
                <div class="row" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ newUser.name }}" [matTooltipDisabled]="!ifOverflow(name)">
                  <input type="checkbox" class="check-box" (change)="toggleSelectUser(newUser)"
                    [checked]="isSelected(newUser)" />
                  <div *ngIf="
                      newUser.userImageThumbnail === null ||
                      newUser.userImageThumbnail === ''
                    ">
                    <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
                  </div>
                  <div *ngIf="newUser.userImageThumbnail !== ''">
                    <img class="employee_img" [(src)]="newUser.userImageThumbnail" alt="img" />
                  </div>
                  <label class="data" #name>{{ newUser.name }}</label>
                </div>
              </td>
              <td colspan="2">
                <label class="data" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{
                    this.getEnumValueByKey(this.jobTitle, newUser.jobTitle)
                  }}" [matTooltipDisabled]="!ifOverflow(jt)" #jt class="data">{{
                  this.getEnumValueByKey(this.jobTitle, newUser.jobTitle)
                  }}</label>
              </td>
              <td colspan="3">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{
                    this.getEnumValueByKey(this.department, newUser.department)
                  }}" [matTooltipDisabled]="!ifOverflow(d)" #d class="data">{{
                  this.getEnumValueByKey(this.department, newUser.department)
                  }}</label>
              </td>
              <td colspan="2">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ newUser.joiningDate | date : 'dd-MM-yyyy' }}" [matTooltipDisabled]="!ifOverflow(jd)"
                  #jd class="data">{{ newUser.joiningDate | date : "dd-MM-yyyy" }}</label>
              </td>
              <td colspan="3">
                <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip="{{ newUser.email }}" [matTooltipDisabled]="!ifOverflow(email)" #email class="data">{{
                  newUser.email }}</label>
              </td>
              <td>
                <label class="data">
                  <!-- Use matMenuTriggerFor and mat-menu directives to create the three dots menu -->
                  <img src="assets/icons/3dots.png" [matMenuTriggerFor]="employeeMenu" class="threedots" />

                  <!-- Use *ngIf to conditionally display the Connect button based on your hasRoleBased variable -->
                  <button class="Connect" [matMenuTriggerFor]="employeeMenu" *ngIf="this.hasRoleBased">
                    Connect
                  </button>

                  <!-- Define the mat-menu and its items -->
                  <mat-menu class="matmenu" #employeeMenu="matMenu" xPosition="before">
                    <button mat-menu-item class="transform-none" (click)="editEmployeeDialog(newUser)">
                      <span class="bold">Edit</span>
                    </button>
                    <button mat-menu-item class="transform-none" (click)="deleteNewUser(newUser)">
                      <span class="bold">Delete</span>
                    </button>
                    <button mat-menu-item class="transform-none" *ngIf="!newUser.activated"
                      (click)="activateEmployee(newUser)">
                      <span class="bold">Activate</span>
                    </button>
                  </mat-menu>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>