import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditAppComponent } from 'src/app/components/edit-app/edit-app.component';
import { FormTemplateDialogComponent } from 'src/app/components/feedback-submission/form-template-dialog/form-template-dialog/form-template-dialog.component';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { FeedbackSubmitStatus } from 'src/app/services/feedback/feedback-enum';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { Overlay } from '@angular/cdk/overlay';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-feedback-form-card-shared',
  templateUrl: './feedback-form-card-shared.component.html',
  styleUrls: ['./feedback-form-card-shared.component.scss'],
})
export class FeedbackFormCardSharedComponent implements OnInit {
  @Input() feedbackForm;
  public formSectionCount;

  private feedbackFormDTO = new FormSchemaDTO();
  public newFormSchema = new FormSchemaDTO();
  formTemplatesModel: any = [];

  // while editing the form, if someone does not change the fromType,
  //at this time if we check if that form exists, it will return yes, it does and wont save the form if we edit it
  // hence, storing the previous formType i.e before editing and getting the desired result
  public previousFormType: string = '';

  constructor(
    public formService: FormsSchemaService,
    private overlay: Overlay,
    private dialog: MatDialog,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  countFormSection(formTemplate) {}

  openEditDialog(feedback): void {
    this.previousFormType = feedback.formType;
    const dialogRef = this.dialog.open(EditAppComponent, {
      width: '95%',
      height: '90%',
      data: {
        property: JSON.parse(feedback.formTemplate),
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && feedback.id != undefined) {
        this.feedbackFormDTO.id = feedback.id;
        this.feedbackFormDTO.formTemplate = JSON.stringify(result);
        this.feedbackFormDTO.formType = result[0].description;
        this.feedbackFormDTO.formName = result[0].description;
        this.feedbackFormDTO.category = 'PROJECT';
        this.feedbackFormDTO.jobTitle = result[0].description.split(' to ')[1];
        this.feedbackFormDTO.feedbackRole =
          result[0].description.split(' to ')[0];

        this.formService.updateFeedbackForm(this.feedbackFormDTO);
      }
    });
  }

  cloneNewFormDialog(feedback): void {
    const dialogRef = this.dialog.open(EditAppComponent, {
      width: '95%',
      height: '90%',
      data: {
        property: JSON.parse(feedback.formTemplate),
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      feedback.id = null;
      if (result != undefined && feedback.id == null) {
        if (result != undefined) {
          this.newFormSchema.formTemplate = result;
          this.formTemplatesModel.push(result);
        }
      }
    });
  }

  saveForm(feedbackFormInfo) {

    this.formService.checkIfFormExists(feedbackFormInfo).subscribe((res) => {

      if (res.body === false) {
        feedbackFormInfo.formTemplate = JSON.stringify(feedbackFormInfo);
        feedbackFormInfo.feedbackRole =
          feedbackFormInfo[0].description.split(' to ')[0];
        feedbackFormInfo.jobTitle =
          feedbackFormInfo[0].description.split(' to ')[1];
        feedbackFormInfo.formType = feedbackFormInfo[0].description;
        feedbackFormInfo.formName = feedbackFormInfo.formType;
        feedbackFormInfo.category = 'PROJECT';

        this.formService
          .addNewFormSchemaSubscribe(feedbackFormInfo)
          .subscribe((res) => {
            this.formService.getFormPagination();
          });
      } else {
        this.toastr.error('Form type already exists', 'Error');
      }
    });
  }

  openFormTemplateDialog(feedbackForm): void {
    if (feedbackForm != undefined) {
      const dialogRef = this.dialog.open(FormTemplateDialogComponent, {
        height: '97%',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          property: feedbackForm.formTemplate,
          disableEdit: true,
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  deleteFeedbackForm(feedbackForm) {
    const ConfirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '95%',
      height: '90%',
      data: {
        property: '',
      },
      disableClose: true,
    });

    ConfirmDialog.afterClosed().subscribe((res) => {

      if (res === 'yes') {
        this.formService.deleteFeedbackForm(feedbackForm.id);
      }
    });
  }
}
