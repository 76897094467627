<div class="row">
  <div class="col-12">
    <div class="header">
      <div class="main_header">
        <img class="logo-imgs" src="/assets/BA_Logo_Icon.png" alt="" />
        <label class="pulse ml-2">Blue Pulse</label>
      </div>
    </div>
  </div>
  <div class="col-12 login-bg">
    <mat-card class="user_login_matcard" *ngIf="!isAdminLogin">
      <div class="container-fluid">
        <div class="row pl-0">
          <div class="col-md-12 pl-0">
            <label class="user_login_header_name ml-0">User</label>
            <label class="user_login_sub_name ml-0">Login Using Microsoft Account</label>
          </div>
        </div>
        <div class="row login_btn">
          <button mat-raised-button type="submit" (click)="signIn()"
            [disabled]="this.authGuardService.msLoginBtnDisabled" class="login-sign-in-button">
            <span>{{ this.authGuardService.msLoginBtnLabel }}</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="main-or-line mt-5 d-flex align-items-center">
          <hr class="hr mr-2" />
          <label class="or"> Or </label>
          <hr class="hr ml-2" />
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mt-4 pl-0">
          <div class="col-md-12 pl-0 pr-0">
            <div class="admin_login_main">
              <label class="admin_login_header">Admin</label>
            </div>
            <div class="admin_login_main">
              <label class="login_header">Login Using Your Credentials</label>
            </div>
            <button
              mat-stroked-button
              type="submit"
              (click)="onclick()"
              class="admin_login_button"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="admin_login_matcard" *ngIf="isAdminLogin">
      <div class="container-fluid">
        <div class="row pl-0">
          <div class="col-md-12 pl-0">
            <label class="secon_admin_login_header_name ml-0">Login</label>
          </div>
          <label class="bad_credentials" *ngIf="this.authGuardService.badCredentials && isAdminLogin">Incorrect Username
            or Password</label>
        </div>
        <div class="row mt-1">
          <form [formGroup]="loginPasswordFormGroup" (ngSubmit)="onClickLoginGetToken()" class="row">
            <div class="col-12">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" autocomplete="off" />
                <mat-error *ngIf="
                    !loginPasswordFormGroup.controls['email'].valid &&
                    loginPasswordFormGroup.controls['email'].touched
                  ">
                  Email is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  [type]="!hide ? 'password' : 'text'"
                  formControlName="password"
                  autocomplete="off"
                />
                <button
                  mat-icon-button
                  matSuffix
                  type="button"
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon style="color: #999999" Pass through
                    >{{ hide ? "visibility_off" : "visibility" }}
                  </mat-icon>
                </button>
                <mat-error *ngIf="
                    !loginPasswordFormGroup.controls['password'].valid &&
                    loginPasswordFormGroup.controls['password'].touched
                  ">
                  Password is required
                </mat-error>
              </mat-form-field>
              <div class="forgotPassword_name">
                <label>
                  <a class="forgotPassword" href="">Forgot Password?</a></label>
              </div>
            </div>
            <div class="col-12">
              <button *ngIf="!this.authGuardService.logInProcess" mat-raised-button type="submit"
                class="mt-2 secondary-login-in-button">
                <span>Login</span>
              </button>
              <button *ngIf="this.authGuardService.logInProcess" mat-raised-button type="submit"
                class="mt-2 secondary-login-in-button" disabled>
                <span>Logging In</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row admin_login_or">
        <div class="main-or-line d-flex align-items-center">
          <hr class="hr mr-2" />
          <label class="or"> Or </label>
          <hr class="hr ml-2" />
        </div>
      </div>
      <div class="container-fluid">
        <div class="row pl-0">
          <div class="col-md-12 pl-0 pr-0">
            <div class="admin_login_main">
              <label class="admin_login_header">Sign In</label>
            </div>
            <div class="microsoft_login_main">
              <label href class="user_signin blue_text" (click)="signIn()">
                Sign In With Microsoft
              </label>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<app-spinner></app-spinner>
