<div>
  <div *ngIf="isSpinner" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>

  <div *ngIf="!isSpinner">
    <!-- This is for viewing another users profile -->
    <div class="container1" *ngIf="!this.loggedInProfile">
      <div class="row mt-3 full-screen-bg-white">
        <div class="col-md-12 scrollable">
          <div class="no-feedbacks" *ngIf="feedback.length == 0">
            No feedbacks Submitted.
          </div>
          <table class="table" *ngIf="feedback.length > 0">
            <thead>
              <th (click)="sortByAlphabetically('name')" class="header-of-feedback">
                Employee
              </th>
              <th (click)="sortByAlphabetically('role')" class="header-of-feedback">
                Given Feedback as
              </th>
              <th>Feedback Summary</th>
              <th (click)="sortByAlphabetically('year')" class="header-of-feedback">
               <img src="assets/icons/filter.png" />
                Year
              </th>
              <th colspan="2" (click)="sortByAlphabetically('status')" class="header-of-feedback">
                <img src="assets/icons/filter.png" />
                Status
              </th>
            </thead>

            <tbody *ngFor="let feedback of feedback" [@slideUp]>
              <tr>
                <td>
                  <label class="content_color1">{{
                    userIdNameMapping[feedback.name]
                    }}</label>
                </td>
                <td>
                  <label class="content_color2">{{
                    feedback.role | underScoreToSpace | titlecase
                    }}</label>
                </td>
                <td>
                  <label class="content_color" matTooltip="{{ feedback.title }}" matTooltipPosition="above">
                    {{ feedback.title.substring(0, 25)
                    }}{{ feedback.title.length > 25 ? ".." : "" }}
                  </label>
                </td>
                <td>
                  <label class="content_color">{{ feedback.year }}</label>
                </td>
                <td>
                  <label class="content_color2">
                    {{ feedback.status | titlecase }}
                  </label>
                </td>
                <td>
                  <label class="content_color2" *ngIf="
                      !feedback.isSubmitted ||
                      feedback.isEditable ||
                      feedback.isContinue ||
                      feedback.isCreated
                    " (click)="editFeedback(feedback)">
                   <img class="icon" src="assets/svg/edit.svg"/>
                  </label>
                  &nbsp;&nbsp;
                  <label class="content_color2" (click)="deleteFeedback(feedback)"
                    *ngIf="!feedback.isSubmitted || feedback.isEditable">
                    <img class="icon" src="assets/svg/delete.svg"/>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- This is for viewing own/loggedIn users profile -->
    <div class="container1">
    <div class="row mt-3 full-screen-bg-white" *ngIf="this.loggedInProfile">
      <div class="col-md-12 scrollable">
        <div class="no-feedbacks" *ngIf="feedbackCount === 0">
          No feedbacks Submitted.
        </div>
        <table class="table" *ngIf="feedbackList.length > 0">
          <thead>
            <th (click)="sortNameByAlphabetically('name')" class="header-of-feedback">
              Employee
            </th>
            <th (click)="sortByAlphabetically('role')" class="header-of-feedback">
              Given Feedback as
            </th>
            <th>Feedback Summary</th>
            <th (click)="sortByAlphabetically('year')" class="header-of-feedback">
              <img src="assets/icons/filter.png" />
              Year
            </th>
            <th colspan="2" (click)="sortByAlphabetically('status')" class="header-of-feedback">
              <img src="assets/icons/filter.png" />
              Status
            </th>
          </thead>

          <tbody *ngFor="let feedback of feedbackList" [@slideUp]>
            <tr>
              <td>
                <label class="content_color1">{{
                  userIdNameMapping[feedback.name]
                  }}</label>
               
              </td>

              <td>
                <label class="content_color2">{{
                  feedback.role | underScoreToSpace | titlecase
                  }}</label>
               
              </td>
              <td>
                <label class="content_color" matTooltip="{{ feedback.title }}" matTooltipPosition="above">
                  {{ feedback.title.substring(0, 25)
                  }}{{ feedback.title.length > 25 ? ".." : "" }}
                </label>
              </td>

              <td>
                <label class="content_color">{{ feedback.year }}</label>
              </td>
              <td>
                <label class="content_color2">
                  {{ feedback.status | titlecase }}
                </label>
              </td>
              <td>
                <label class="content_color2" *ngIf="
                    !feedback.isSubmitted ||
                    feedback.isEditable ||
                    feedback.isContinue ||
                    feedback.isCreated
                  " (click)="editFeedback(feedback)">
                 
                  <img class="icon" src="assets/svg/edit.svg"/>

                </label>
                &nbsp;&nbsp;
                <label class="content_color2" (click)="deleteFeedback(feedback)"
                  *ngIf="!feedback.isSubmitted || feedback.isEditable">
                  <img class="icon" src="assets/svg/delete.svg"/>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
</div>