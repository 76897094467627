import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReopenedConfirmationScreenComponent } from 'src/app/reopened-confirmation-screen/reopened-confirmation-screen.component';
import { ToastrService } from 'ngx-toastr';
import { NoDataAvailableComponent } from './no-data-available/no-data-available.component';
import { ProfileDetailComponent } from '../profile-detail/profile-detail.component';
import { GeoLocation } from 'src/app/services/user/user-enum';

@Component({
  selector: 'app-calibrated-report',
  templateUrl: './calibrated-report.component.html',
  styleUrls: ['./calibrated-report.component.scss'],
})
export class CalibratedReportComponent implements OnInit {
  selected: number;
  nodata: Boolean = true;
  years = [];
  userDtao = [];
  isAll: Boolean = true;
  isOpen: Boolean = false;
  isClose: Boolean = false;
  selectedHrLocation = '';
  locationArray = [];
  searchText: string;
  loginUserInfo;
  isSuperAdmin: Boolean = false;
  data = [];
  isLocationDropDownTouched: Boolean = false;

  constructor(
    private feedbackgroupservice: NewFeedbackGroupService,
    private userDetailService: UserDetailsService,
    private dialogue: MatDialog
  ) {}

  ngOnInit(): void {
    this.locationArray = Object.keys(GeoLocation);
    this.isSuperAdmin = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN']);
    this.loginUserInfo = this.userDetailService.getUserDetails();
    this.selectedHrLocation =
      this.loginUserInfo.geoLocation != null
        ? this.loginUserInfo.geoLocation
        : '';
    if (this.isSuperAdmin && this.selectedHrLocation == '') {
      this.selectedHrLocation = 'INDIA';
    }
    this.getCalendarNewDate();
    this.getAllEmployeeForCalibratedScore();
  }

  getCalendarNewDate() {
    this.selected = new Date().getFullYear();
    for (
      let year = this.selected;
      year >= new Date().getFullYear() - 1;
      year--
    ) {
      this.years.push(year);
    }
  }

  onViewReportee(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.maxWidth = '95%';
    dialogConfig.data = id;
    const dialogRef = this.dialogue.open(ProfileDetailComponent, dialogConfig);
  }

  getAllEmployeeForCalibratedScore() {
    this.feedbackgroupservice
      .getdirectreporteebyyearforHr(this.selected)
      .subscribe((res) => {
        this.data = res.body;
        this.userDtao = res.body;
        if (res.body.length != 0) {
          this.nodata = false;
        }
        if (this.isAll == true) {
          this.All();
        } else if (this.isOpen == true) {
          this.Open();
        } else {
          this.Close();
        }
      });
  }

  changeYear(e: any) {
    this.selected = e.value;
    this.getAllEmployeeForCalibratedScore();
  }

  Open() {
    this.userDtao = this.data.filter((obj) => {
      return (
        (this.selectedHrLocation.toString() == obj.userLocation.toString() ||
          !this.isLocationDropDownTouched) &&
        !obj.closed
      );
    });
    this.isOpen = true;
    this.isClose = false;
    this.isAll = false;
  }

  Close() {
    this.userDtao = this.data.filter((obj) => {
      return (
        (this.selectedHrLocation.toString() == obj.userLocation.toString() ||
          !this.isLocationDropDownTouched) &&
        obj.closed
      );
    });
    this.isOpen = false;
    this.isClose = true;
    this.isAll = false;
  }

  All() {
    this.userDtao = this.data.filter((obj) => {
      return (
        this.selectedHrLocation.toString() == obj.userLocation.toString() ||
        !this.isLocationDropDownTouched
      );
    });
    this.isOpen = false;
    this.isClose = false;
    this.isAll = true;
  }

  changeLocation(e) {
    this.isLocationDropDownTouched = true;
    this.selectedHrLocation = e.value.toUpperCase();
    if (this.isOpen == true) {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString() && !obj.closed;
      });
    } else if (this.isClose == true) {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString() && obj.closed;
      });
    } else {
      this.userDtao = this.data.filter((obj) => {
        return e.value.toString() == obj.userLocation.toString();
      });
    }
  }

  getValueByKey(key: string): string | undefined {
    const enumValues = Object.values(GeoLocation) as string[];
    const enumKeys = Object.keys(GeoLocation);
    const index = enumKeys.indexOf(key);

    if (index !== -1) {
      return enumValues[index];
    }

    return undefined;
  }
}
