<div class="body-container">
  <div class="class=col-md-12 main-container">
    <ng-container *ngIf="this.nodata == true; else elseTemplate">
      <div class="nothing-data">
        <img class="no-reportee-img" src="assets/no-data-project.png" />
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="container">
        <div class="div-30-0">
          <button
            class="btn-1"
            [ngClass]="{ blue: this.isAll }"
            (click)="All()"
          >
            All
          </button>
          <button
            class="btn-1"
            [ngClass]="{ blue: this.isOpen }"
            (click)="Open()"
          >
            Open
          </button>
          <button
            class="btn-1"
            [ngClass]="{ blue: this.isClose }"
            (click)="Close()"
          >
            Close
          </button>
        </div>
        <div class="div-30">
          <div class="year-div">
            <mat-select
              class="year"
              [(value)]="selected"
              (selectionChange)="changeYear($event)"
            >
              <mat-option *ngFor="let year of years" [value]="year">
                <span class="year-selected">{{ year }}</span></mat-option
              >
            </mat-select>
          </div>
          <div class="year-div">
            <mat-select
              class="year"
              [(value)]="selectedHrLocation"
              (selectionChange)="changeLocation($event)"
              [disabled]="!this.isSuperAdmin"
            >
              <mat-option
                *ngFor="let location of locationArray"
                [value]="location"
                >{{ this.getValueByKey(location) }}</mat-option
              >
            </mat-select>
          </div>
        </div>
        <div class="div-40">
          <div class="row-main">
            <img src="assets/icons/search-black.png"/>
            <input
              class="search_input"
              type="search"
              placeholder="Search"
              [(ngModel)]="searchText"
              value=""
            />
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="contianer-2">
    <div class="card-container">
      <table>
        <thead>
          <tr>
            <th class="th-emp-name">Employee</th>
            <th class="th-emp-title">Title</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="emp-row"
            *ngFor="let directreportee of userDtao | filter : searchText"
            (click)="onViewReportee(directreportee.userId)"
          >
            <td>
              <label class="name-user">
                ({{ directreportee.employeeId }})
                {{ directreportee.firstName | titlecase }}
                {{ directreportee.lastName | titlecase }}
              </label>
            </td>
            <td>
              <label class="title-user">
                {{ directreportee.jobTitle | underScoreToSpace | titlecase }}
              </label>
            </td>
            <td>
              <label class="isclosed" *ngIf="directreportee.closed">
                Closed
              </label>
              <label class="link-download" *ngIf="!directreportee.closed">
                Open
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
