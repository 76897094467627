<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">Feedback Review Meeting Notes</h1>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
  <hr />
  <div [innerHTML]="feedbackClosureNotes"></div>
</div>
